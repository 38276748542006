import React, { useEffect, useRef, useState } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import routeNames from 'src/constants/routeNames';
import * as Sentry from '@sentry/react-native';
import useProfile from 'src/hooks/useProfile';
import { chatgenieAPI } from 'src/lib/api/chatgenieApi';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { getCompanyDesign, normalizeUserInfo } from 'src/utils/normalizer';
import { updateProfileAction } from 'src/redux/profile/profileActions';
import useTheme from 'src/hooks/useTheme';
import { colors, fonts } from 'src/constants/theme';
import GoBackButton from 'src/components/GoBackButton';
import { GoBackIcon } from 'src/assets/icons';
import Common from 'src/components/Common';
import { StyleSheet, View } from 'react-native';
import { useTwilioClient } from '@Thread-Magic/chat-utils';
import { isIOS, isWeb } from 'src/utils';
import { reportException } from 'src/utils/monitoring';
import usePushNotification from 'src/hooks/usePushNotification';
import { useSafeArea } from 'react-native-safe-area-context';
import { routes } from './routes';
import { Alerts } from 'src/components/ConnectionIndicator';
import useTranslation from 'src/hooks/useTranslation';
import { APP_NAVIGATOR_STATES } from 'src/constants';
import { useFetch } from 'src/hooks/useFetch';
import reactQueryKeys from 'src/constants/reactQueryKeys';

const Stack = createStackNavigator();
const COMPANY_INFO_API_STALE_TIME = 1000 * 60 * 5; // 5 minutes

const MainStack = () => {
  const { companyInfo, userInfo, parentCompanyInfo, appNavigatorState } = useProfile();
  const { handleMessageNotification } = usePushNotification();
  const saveProfile = useDispatchMapper(updateProfileAction);
  const [fetchedUserInfo, setFetchedUserInfo] = useState(false);
  const [isFetchingUserInfo, setIsFetchingUserInfo] = useState(false);
  const { theme, isDarkMode } = useTheme();
  const [isTwilioInitializingFailed, setIsTwilioInitializingFailed] = useState(false);
  const insets = useSafeArea();
  const { status, initializeClient, client, reconnect } = useTwilioClient();
  const statusRef = useRef();
  const { translate } = useTranslation();
  const companyInfoRef = useRef(companyInfo);

  statusRef.current = status;

  const getToken = (email) => {
    if (!email) {
      reportException(new Error('No email value while getting token'));
      return null;
    } else {
      const pushChannel = isIOS() ? 'apn' : 'fcm';
      return chatgenieAPI
        .getTwilioToken(email, pushChannel)
        .then((res) => res?.data?.data?.token)
        .catch((err) => reportException(err));
    }
  };

  const fetchCompanyInfo = async () => {
    if (!companyInfo?.appId) return;

    try {
      const {
        data: { data },
      } = await chatgenieAPI.getCompanyBasicInfo(companyInfoRef.current.appId);
      const normalized = { ...companyInfoRef.current, ...getCompanyDesign(data) };

      saveProfile({ companyInfo: normalized });

      if (window?.ipc) {
        window.ipc?.send('store-data', { key: 'companyInfo', value: normalized });
      }
    } catch (err) {
      console.log(err);
      reportException(new Error(`MainStack fetchCompanyInfo: ${err.message}`));
    }
  };

  const fetchUserInfo = async () => {
    setFetchedUserInfo(true);
    setIsFetchingUserInfo(true);
    try {
      const data = await chatgenieAPI.getUserInfo(userInfo?.id);
      const { integration, ...rest } = normalizeUserInfo(data);
      const updatedProfileState = { userInfo: { ...userInfo, ...rest }, integration };
      saveProfile(updatedProfileState);
    } catch (err) {
      console.log(err);
      reportException(`MainStack fetchUserInfo: ${err.message}`);
    }
    setIsFetchingUserInfo(false);
  };

  const trackTwilioConnection = () => {
    if (statusRef.current === 'denied' && client) reconnect();
  };

  const subscribeToTwilioMessageAddedEvent = async (twilioClient) => {
    twilioClient?.on('messageAdded', async (newMessage) => {
      try {
        const { ticket: threadNotification = {} } = newMessage.attributes;
        const isThreadNotification = threadNotification?.id;

        // thread notification is handled from thread-service Websockets
        // see useThreadSubscriptions ON_CONTACT_THREAD_ADDED event
        if (isThreadNotification) return;

        handleMessageNotification(newMessage);
      } catch (err) {
        Sentry.captureException(new Error(`subscribeToTwilioMessageAddedEvent failed: ${err.message}`));
      }
    });
  };

  useFetch(reactQueryKeys.COMPANY_INFO, fetchCompanyInfo, {
    refetchOnWindowFocus: true,
    staleTime: COMPANY_INFO_API_STALE_TIME,
  });

  // TODO: remove this useEffect and the log, once we decide to enable NoAppId flow again
  useEffect(() => {
    if (appNavigatorState === APP_NAVIGATOR_STATES.hasNoAppId) {
      Sentry.captureMessage('User logged in with no appId', {
        extra: { companyInfo },
      });
    }
  }, [appNavigatorState]);

  useEffect(() => {
    if (isWeb()) {
      window.addEventListener('focus', trackTwilioConnection, false);
      return () => {
        window.removeEventListener('focus', trackTwilioConnection, false);
      };
    }
    return () => {};
  }, [client]);

  // NB: this is necessary because Redux Store "companyInfo" is not updated on fetchCompanyInfo() after selecting a company (check SelectCompany/index.js)
  // Side effect: it gets the old appId which comes from MSP and then, if the user is also a member,
  // signs in the user as a member on Messenger
  useEffect(() => {
    companyInfoRef.current = companyInfo;

    return () => {};
  }, [companyInfo]);

  const showCompanySelectPage = !companyInfo?.id || !companyInfo?.isChildCompany;

  useEffect(() => {
    if (userInfo?.id && companyInfo?.id && !fetchedUserInfo) {
      fetchUserInfo();
    }
    try {
      Sentry.setUser({ email: userInfo?.email, id: userInfo?.id });
      Sentry.setTags({
        email: userInfo?.email,
        company: companyInfo?.name,
        appId: companyInfo?.appId,
        parentCompanyId: companyInfo?.parentId,
        parentAppId: parentCompanyInfo?.app_id,
      });
      Sentry.setContext('company', {
        appId: companyInfo?.appId,
        name: companyInfo?.name,
        companyId: companyInfo?.id,
        parentCompanyId: companyInfo?.parentId,
        parentAppId: parentCompanyInfo?.app_id,
      });
    } catch (err) {
      console.log('Sentry error:', err);
      reportException(new Error('Error while configuring Sentry context'));
    }
  }, [userInfo, fetchedUserInfo, companyInfo]);

  useEffect(() => {
    if (userInfo?.email && companyInfo?.id && !client && status !== 'connecting') {
      initializeClient(() => getToken(userInfo.email))
        .then(subscribeToTwilioMessageAddedEvent)
        .catch((err) => {
          setIsTwilioInitializingFailed(true);

          if (err?.response && err.response?.status !== 403) {
            reportException(new Error(`TwilioInitializingFailed: ${err.message}`), {
              extra: { errorCode: err?.errorCode },
            });
          }
        });
    }
  }, [userInfo, client, companyInfo]);

  useEffect(() => {
    if (client && userInfo?.fullname) {
      client
        .getUser(userInfo.email)
        .then(
          (twilioUser) =>
            userInfo.fullname !== twilioUser.friendlyName && twilioUser.updateFriendlyName(userInfo.fullname),
        )
        .catch((err) => reportException(err));
    }
  }, [client, userInfo?.fullname]);

  useEffect(() => {
    if (client) {
      setIsTwilioInitializingFailed(false);
    }
  }, [client]);

  return (
    <>
      {isFetchingUserInfo && (
        <View style={styles.spinnerWrapper}>
          <Common.Spinner />
        </View>
      )}
      {isTwilioInitializingFailed && <Alerts title={translate('common.alert.wrong')} />}
      <Stack.Navigator
        headerMode="screen"
        initialRouteName={showCompanySelectPage ? routeNames.SELECT_COMPANY : routeNames.NOW}
        screenOptions={{
          headerStyle: [
            styles.headerStyle,
            { height: insets.top + 56 },
            isDarkMode && { backgroundColor: theme.foreground },
          ],
          headerTitleAlign: 'center',
          headerTitleStyle: [styles.headerTitleStyle, { color: theme.primaryColor }],
          cardStyle: { flex: 1 },
          headerLeft: (props) => <GoBackButton {...props} />,
          headerBackImage: (props) => <GoBackIcon fill={theme.primaryColor} {...props} />,
        }}
      >
        {routes.main.map((stackRoute) => (
          <Stack.Screen key={stackRoute.name} {...stackRoute} />
        ))}
      </Stack.Navigator>
    </>
  );
};

export default MainStack;

export const styles = StyleSheet.create({
  spinnerWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: colors.backgroundColor,
    zIndex: 999,
  },
  headerStyle: {
    backgroundColor: colors.white,
    height: 72,
    borderBottomWidth: 0,
    shadowColor: '#000',
    shadowOffset: {
      height: 0,
      width: 0,
    },
    shadowRadius: 2,
    shadowOpacity: 0.1,
  },
  headerTitleStyle: {
    fontSize: 20,
    fontWeight: '700',
    fontFamily: fonts.regular,
    display: 'flex',
    alignItems: 'center',
  },
});
