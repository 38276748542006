import actionTypes from 'src/constants/actionTypes';
import { CHAT_NEW_TICKET_STATES } from 'src/redux/chat/chatStateConstants';

const defaultState = {
  ticket: null,
  messages: [],
  chatHistoryUnread: null,
  notificationCount: 0,
  activeChannel: null,
  twilioChannel: null,
  activeTicket: null,
  isMinimized: false,
  newTicketCreationState: CHAT_NEW_TICKET_STATES.INITIAL,
};

const chatReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_COUNT_INCREMENT:
      return { ...state, notificationCount: state.notificationCount + 1 };
    case actionTypes.CHAT_MESSAGES:
      return { ...state, messages: [...state.messages, action.payload] };
    case actionTypes.EDIT_CHAT_MESSAGE:
      return {
        ...state,
        messages: state.messages.map((el) => (el.id === action.payload.id ? { ...el, ...action.payload } : el)),
      };
    case actionTypes.CHAT_HISTORY_UNREAD_UPDATE:
      return { ...state, chatHistoryUnread: action.payload };
    case actionTypes.CHAT_TICKET:
      return { ...state, ticket: action.payload };
    case actionTypes.SET_ACTIVE_CHANNEL:
      return { ...state, activeChannel: action.payload };
    case actionTypes.SET_TWILIO_CHANNEL:
      return { ...state, twilioChannel: action.payload };
    case actionTypes.SET_ACTIVE_TICKET:
      return { ...state, activeTicket: action.payload };
    case actionTypes.CHAT_NEW_TICKET_CREATION_STATE:
      return { ...state, newTicketCreationState: action.payload };
    case actionTypes.RESET_CHAT:
      return defaultState;
    default:
      return state;
  }
};

export default chatReducer;
