import { gql } from '@Thread-Magic/thread-service-utils';
import { FEATURE_FLAGS, isFeatureFlagEnabled } from 'src/config/featureFlags';

export const APOLLO_CLIENT_STATUSES = {
  opened: 'opened',
  connecting: 'connecting',
  connected: 'connected',
  closed: 'closed',
  error: 'error',
};

export const CORE_THREAD_FIELDS = () => {
  const profilePictureEnabled = isFeatureFlagEnabled(FEATURE_FLAGS.PROFILE_PICTURES);
  const profileImageGQL = 'profile_image { id path }';
  const memberGQL = `member { id name ${profileImageGQL}  }`;

  return gql`
    fragment CoreThreadFields on Thread {
      id
      number
      system_id
      summary
      created_at
      updated_at
      assignee
      member_id
      status
      state
      contact_name
      contact {
        id
        name
        full_name
        ${profilePictureEnabled ? profileImageGQL : ''}
        company {
          name
        }
      }
      status_object {
        id
        name
        closed_status
        is_default
        board_id
      }
      conversation_channel {
        id
        name
        friendly_name
      }
      last_note {
        id
        created_at
        text
        is_internal
        sender_fullname
      }
      priority {
        name
        color
      }
      ${profilePictureEnabled ? memberGQL : ''}
      contacts {
        id
        name
        full_name
        ${profilePictureEnabled ? profileImageGQL : ''}
      }
      members {
        id
        name
        ${profilePictureEnabled ? profileImageGQL : ''}
      }
    }
  `;
};
